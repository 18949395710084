<template>
  <div class="editNicknameBox">
    <div class="editNickname">
      <div class="userBox">
        <section class="itemBox newItemBox">
          <p class="itemBoxLeft">{{ $t.userInterpret.inputNewName }}</p>
          <input
            class="itemBoxRight"
            v-model="newNameText"
            :placeholder="$t.userInterpret.inputYourName"
          />
        </section>
      </div>
    </div>
    <div class="loginOutBox" @click="onSubmit">
      <div class="loginOut">{{ $t.confirm }}</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "EditNickname",
  data() {
    return {
      newNameText: "",
    };
  },
  methods: {
    onSubmit() {
      const name = this.newNameText;
      if (name) {
        let param = {
          NickName: name,
        };
        this.$api.user
          .EditUserInfo(param)
          .then((res) => {
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.showNotify,
              {
                titleMsg: res.message,
                type: "success",
              }
            );
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.routerPush,
              {
                isParams: false,
                path: "user/usermanage",
                name: "UserManage",
                query: [],
              }
            );
          })
          .catch((err) => {
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.showNotify,
              {
                titleMsg: err.message,
                type: "danger",
              }
            );
          });
      } else {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
          type: "danger",
          titleMsg: this.$t.userInterpret.inputYourName,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin newBox1 {
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 0.8rem;
}
@mixin boxLeft {
  max-width: 35%;
  text-align: left;
  color: #000000;
}
@mixin boxright {
  max-width: 65%;
  text-align: right;
  font-size: 0.8rem;
  color: #000000;
  font-weight: bold;
  background: none;
  outline: none;
  border: none;
}
.editNicknameBox {
  width: $publicWidth;
  margin: 0 auto;
  .editNickname {
    font-size: 0.8rem;
    margin: 0 auto;
    .userBox {
      @include newBox1;
      .itemBox {
        @include publicFlex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        padding: 18px 15px;
        .itemBoxLeft {
          @include boxLeft;
        }
        .itemBoxRight {
          @include boxright;
        }
        .address {
          width: 100%;
          border: none;
        }
      }
      .newItemBox {
        border-bottom: 1px solid #f6f6f6;
      }
    }
  }
  .loginOutBox {
    width: $publicWidth;
    margin: 8% auto;
    .loginOut {
      position: relative;
      width: 100%;
      margin: 0 auto;
      border-radius: 3rem;
      padding: 0.8rem 0;
      text-align: center;
      background: linear-gradient(left, #71d283, #01aaa3);
      color: #ffffff;
      font-size: 1rem;
      line-height: 1;
    }
  }
}
</style>